<template>
  <div class="index">
    <div class="aside">
      <div class="top" v-show="!isCollapse">
        <img src="../assets/imgs/logo2.png" alt="" class="img" />
      </div>
      <el-menu
        :default-active="this.$route.path"
        class="el-menu-vertical-demo"
        :router="true"
        text-color="#999"
        :collapse="isCollapse"
        :unique-opened="true"
      >
        <div v-for="item in menuData" :key="item.name">
          <el-submenu
            v-show="item.children && item.isShow == 1"
            :index="item.name"
          >
            <template slot="title">
              <i :class="item.menuIcon"></i>
              <span slot="title">{{ item.name }}</span>
            </template>
            <el-menu-item-group>
              <el-menu-item
                v-for="item1 in item.children"
                :key="item1.name"
                :index="item1.url"
                @click="addList(item1.url, item1.name, item.sort)"
                v-show="item1.isShow == 1"
                >{{ item1.name }}
              </el-menu-item>
            </el-menu-item-group>
          </el-submenu>

          <el-menu-item
            v-show="item.children == undefined && item.isShow == 1"
            :index="item.url"
            @click="addList(item.url, item.name, item.sort)"
          >
            <template slot="title">
              <i :class="item.menuIcon"></i>
              <span slot="title">{{ item.name }}</span>
            </template>
          </el-menu-item>
        </div>
      </el-menu>
      <img
        src="../assets/imgs/menu.png"
        alt=""
        class="bottom_img"
        v-show="!isCollapse"
      />
    </div>
    <div class="main">
      <header>
        <div class="left">
          <div>
            <img
              style="
                width: 34px;
                height: 22px;
                margin-top: 10px;
                margin-left: 8px;
                margin-right: 10px;
              "
              src="../assets/imgs/menu1.png"
              alt=""
              v-show="menu === 1"
              @click="menuChange(2)"
            />
            <img
              style="
                width: 34px;
                height: 22px;
                margin-top: 10px;
                margin-left: 8px;
                margin-right: 10px;
              "
              src="../assets/imgs/menu3.png"
              alt=""
              v-show="menu === 2"
              @click="menuChange(1)"
            />
          </div>
          <p class="arrow" @click="$router.go(-1)"><span></span>返回</p>
          <div
            class="head"
            v-for="(item, index) in headList"
            :key="item.name"
            @mouseover="headactive = index"
            @mouseout="headactive = 100"
            @click="$router.push(item.url)"
            :class="{ head_active: item.url == $route.path }"
          >
            <transition name="slide-fade">
              <span
                class="head_border"
                v-show="item.url == $route.path || index == headactive"
              ></span>
            </transition>
            <span>{{ item.name }}</span>
            <span
              class="close"
              v-show="headList.length < 17"
              @click.stop="deleteItem(item)"
              ><img
                class="close_img"
                src="../assets/imgs/colse_index.png"
                alt=""
            /></span>
          </div>
        </div>

        <div class="right">
          <span
            @click="colse"
            style="cursor: pointer; margin-right: 10px"
            v-show="headList.length > 1"
            >关闭标签</span
          >
          <!-- <div class="message">

            <el-dropdown trigger="click">
              <span class="el-dropdown-link">
                待办<i class="el-icon-caret-bottom el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item class="clearfix" v-for="item in wsData" :key="item.name">
                  <span @click="$router.push(item.path)" style="margin-right:4px">{{item.name}}</span> 
                  <el-badge class="mark" :value="item.num" />
                </el-dropdown-item>

              </el-dropdown-menu>
            </el-dropdown>
          </div> -->
          <el-popover placement="bottom" trigger="hover">
            <div>
              <p
                style="cursor: pointer"
                @click="
                  indexModal = 1;
                  cgpwd = 1;
                "
              >
                <span style="margin-left: 6px">修改密码</span>
              </p>
            </div>
            <el-button slot="reference">{{ name }}</el-button>
          </el-popover>
          <span class="unmean" @click="out()">退出</span>
        </div>
      </header>
      <div class="content">
        <router-view />
      </div>
    </div>
    <div class="indexModal111" v-if="indexModal == 1">
      <div class="inner">
        <p class="title">
          <span>修改密码</span>
          <span
            v-show="d == 0 || cgpwd == 1"
            @click="
              indexModal = 0;
              littleForm = {};
            "
            class="pointer"
          >
            <i class="el-icon-circle-close"></i>
          </span>
        </p>
        <div class="modal_item3">
          <div class="input_item">
            <p>旧密码：</p>
            <el-input v-model="form.oldPassword" size="mini"></el-input>
          </div>
          <div class="input_item">
            <p>新密码：</p>
            <el-input
              v-model="form.newPassword"
              show-password
              size="mini"
            ></el-input>
          </div>
          <div class="input_item">
            <p>确认新密码：</p>
            <el-input
              v-model="form.newPassword1"
              show-password
              size="mini"
            ></el-input>
          </div>
        </div>
        <div class="foot">
          <button class="finish" @click="passWordChange">完成</button>
        </div>
      </div>
    </div>
    <transition name="slide-fade">
    <div class="dbModal" v-show="dbModal==0&&wsData.length>0">
        <p class="title">
          <span style="color: #0090ff;">待办事项({{num}})</span>
          <span
            @click="
              dbModal = 1;
            "
            class="pointer"
          >
            <i class="el-icon-close"></i>
          </span>
        </p>
        <div class="dbModal_item  scrollbar_width0">
            <div v-for="item in wsData" :key="item.name" style="margin-bottom:20px">
              <el-badge :value="item.num" class="item" >
              <el-button @click="dbclick(item.path)" size="small">{{item.name}}</el-button>
            </el-badge>
            </div>
            
        </div>
    </div>
     </transition>
     <transition name="slide-fade">
    <div class="dbModal1" v-show="dbModal==1&&wsData.length>0" @click="dbModal = 0;">
        <p>待</p>
        <p>办</p>
    </div>
     </transition>
  </div>
</template>

<script>
import {
  loginedAumenu,
  getUserInfo,
  logout,
  dosth,
  editPwd,
  queryAllPermission,
  axiosUrl,
} from "@/api/apis.js";
import Utils from "@/utils/util.js";
import { timestampToTime } from "@/utils/getTime.js";

export default {
  data() {
    return {
      officeId: "",
      offices: [],
      bgc: "1",
      menu: 1,
      headList: [],
      name: "",
      indexModal: 0,
      wsData: [],
      form: {},
      d: 0,
      cgpwd: 0,
      loginType: 1000,
      menuData: [],
      //当前展开菜单
      menuTitle: 1,
      headActive: 0,
      headactive: 100,
      isCollapse: false,
      websocket: null,
      dbModal:0,
      num:0,
    };
  },
  mounted() {
    let userId = localStorage.getItem("ld");
    var _this=this
    let socket = null;
    let lockReconnet = false;
    //避免重复连接
    const wsUrl = `${axiosUrl}/admin/l/websocket/${userId}`
      .replace("https://", "wss://")
      .replace("http://", "ws://");
    var reconnet = (url) => {
      //重新连接websock函数
      if (lockReconnet) return false;
      lockReconnet = true;
      setTimeout(() => {
        createSocket(url);
        lockReconnet = false;
      }, 2000);
    };
    var createSocket = (url) => {
      try {
        if ("WebSocket" in window) {
          socket = new WebSocket(url);
          this.WebSocket=socket
        }
        initSocket();
      } catch (e) {
        reconnet(url);
      }
    };
    createSocket(wsUrl);
    var initSocket = () => {
      //初始化websocket
      socket.onopen = () => {
        heartCheck.reset().start();
      };

      socket.onmessage = (ev) => {
        // console.log(ev);
        if(ev.data!=='ping'){
          _this.wsDataChange(JSON.parse(ev.data))
        }
        

        heartCheck.reset();
      };

      socket.onerror = () => {
        // console.log('websocket服务出错了---onerror');
        reconnet(wsUrl);
      };

      socket.onclose = () => {
        // console.log('websocket服务关闭了+++onclose');
        reconnet(wsUrl);
      };
    };

    var heartCheck = {
      //心跳检测
      timeout: 60 * 1000,
      timeoutObj: null,
      serverTimeoutObj: null,
      reset() {
        clearTimeout(this.timeoutObj);
        clearTimeout(this.serverTimeoutObj);
        return this;
      },
      start() {
        let that = this;
        this.timeoutObj = setTimeout(() => {
          //发送数据，如果onmessage能接收到数据，表示连接正常,然后在onmessage里面执行reset方法清除定时器
          socket.send("heart check");
          this.serverTimeoutObj = setTimeout(() => {
            socket.close();
          }, that.timeout);
        }, this.timeout);
      },
    };

    getUserInfo().then((res) => {
      if (res.code == 200) {
        this.name = res.data.nickName;
        this.$message({
          dangerouslyUseHTMLString: true,
          message: `
            <p style='margin-bottom:10px'>上次登录时间：${timestampToTime(
              res.data.lastLoginDate
            )}</p>
            <p>  上次登录IP：${res.data.lastLoginIp}</p>
          `,
        });
      }
    });
  },

  methods: {
    //关闭标签
    colse() {
      this.headList = [{ url: this.$route.path, name: this.$route.meta }];
    },
    menuChange(i) {
      this.menu = i;
      this.isCollapse = !this.isCollapse;
    },
    //修改密码
    passWordChange() {
      let flag = true;
      if (this.form.oldPassword == this.form.newPassword) {
        this.$message.error("新旧密码一致，请重新输入");
        flag = false;
      }
      if (this.form.oldPassword == this.form.newPassword1) {
        this.$message.error("两次输入密码不一致，请重新输入");
        flag = false;
      }
      if (flag) {
        let data = {
          orginlPwd: this.form.oldPassword,
          newPwd: this.form.newPassword,
          reNewPwd: this.form.newPassword1,
        };
        editPwd(data).then((res) => {
          if (res.code == 200) {
            this.$message({
              type: "success",
              message: "修改成功，请重新登陆",
            });
            this.indexModal = 0;
            this.out();
          } else {
            this.$message.error(res.data.msg);
          }
        });
      }
    },
    //加入数组
    addList(url, name, sort) {
      let obj = { url, name, sort };
      let flag = true;
      this.headList.forEach((v) => {
        if (v.url == obj.url) {
          flag = !flag;
        }
      });
      if (flag) {
        this.headList.push(obj);
      }
    },
    //删除
    deleteItem(item) {
      if (this.headList.length == 1) {
        return;
      }
      let index = 0;
      this.headList.forEach((v, i) => {
        if (v.url == item.url) {
          index = i;
        }
      });
      this.headList.splice(index, 1);
      if (this.headList.length == index) {
        this.$router.push(this.headList[index - 1].url);
      } else {
        this.$router.push(this.headList[index].url);
      }
    },
    //点击待办
    dbclick(path){
      this.$router.push(path)
                let flag = true;
      this.headList.forEach((v) => {
        if (v.url == path) {
          flag = !flag;
        }
      });
      if (flag) {
        let str =''
        this.$router.options.routes[1].children.forEach(item=>{
              if(item.path==path){
                str=item.meta
              }
        })
        this.headList.push({
          url: path, name: str
        });
      }
    },
    //退出
    out() {
      logout();
      localStorage.removeItem("p");
      localStorage.removeItem("d");
      localStorage.removeItem("ld");
      localStorage.removeItem("perArr");
      this.websocket.close()
      this.$router.push("/");
      
    },
    wsDataChange(data) {
      this.wsData = [];
      this.num=0
      for (const key in data) {
        switch (key) {
          case "1":
            this.wsData.push({
              name: "安装结果确认",
              num: data[key],
              path: "/index/Order_plan",
            });
            break;
          case "18":
            this.wsData.push({
              name: "采购待审核",
              num: data[key],
              path: "/index/purchase",
            });
            break;
          case "19":
            this.wsData.push({
              name: "付款待审核",
              num: data[key],
              path: "/index/Payment",
            });
            break;
          case "11":
            this.wsData.push({
              name: "开票通知",
              num: data[key],
              path: "/index/Billing_record",
            });
            break;
          case "10":
            this.wsData.push({
              name: "回款通知 ",
              num: data[key],
              path: "/index/Payment_collection",
            });
            break;
          case "12":
            this.wsData.push({
              name: "发票作废通知",
              num: data[key],
              path: "/index/Invalid",
            });
            break;
          case "13":
            this.wsData.push({
              name: "采购支付通知",
              num: data[key],
              path: "/index/purchase",
            });
            break;
          case "14":
            this.wsData.push({
              name: "付款支付通知",
              num: data[key],
              path: "/index/Payment",
            });
            break;
          case "112":
            this.wsData.push({
              name: "安装待申领",
              num: data[key],
              path: "/index/Order_plan",
            });
            break;
          case "113":
            this.wsData.push({
              name: "安装待申领(渠道)",
              num: data[key],
              path: "/index/Channel",
            });
            break;
          case "105":
            this.wsData.push({
              name: "安装待派单(渠道)",
              num: data[key],
              path: "/index/Channel",
            });
            break;
          case "102":
            this.wsData.push({
              name: "渠道待审核",
              num: data[key],
              path: "/index/Channel",
            });
            break;
          case "103":
            this.wsData.push({
              name: "商务待审核",
              num: data[key],
              path: "/index/Business",
            });
            break;
          case "104":
            this.wsData.push({
              name: "安心待审核",
              num: data[key],
              path: "/index/Peace",
            });
            break;
          case "110":
            this.wsData.push({
              name: "安装待出库(渠道)",
              num: data[key],
              path: "/index/Channel",
            });
            break;
          case "116":
            this.wsData.push({
              name: "安装待实施(渠道)",
              num: data[key],
              path: "/index/Channel",
            });
            break;
          case "200":
            this.wsData.push({
              name: "库存预警",
              num: data[key],
              path: "/index/Service",
            });
            break;
          case "114":
            this.wsData.push({
              name: "维修待申领",
              num: data[key],
              path: "/index/Repair",
            });
            break;
          case "107":
            this.wsData.push({
              name: "维修待派单",
              num: data[key],
              path: "/index/Repair",
            });
            break;
          case "109":
            this.wsData.push({
              name: "维修待出库",
              num: data[key],
              path: "/index/Repair",
            });
            break;
          case "117":
            this.wsData.push({
              name: "维修待实施",
              num: data[key],
              path: "/index/Repair",
            });
            break;
          case "118":
            this.wsData.push({
              name: "运营待实施",
              num: data[key],
              path: "/index/Operate",
            });
            break;
          case "115":
            this.wsData.push({
              name: "安装待实施",
              num: data[key],
              path: "/index/Order_plan",
            });
            break;
          case "106":
            this.wsData.push({
              name: "安装待派单",
              num: data[key],
              path: "/index/Order_plan",
            });
            break;
          case "108":
            this.wsData.push({
              name: "安装待出库",
              num: data[key],
              path: "/index/Order_plan",
            });
            break;
          case "101":
            this.wsData.push({
              name: "安装待审核",
              num: data[key],
              path: "/index/Order_plan",
            });
            break;

          default:
            break;
        }
      }
      this.wsData.forEach(item=>{
        this.num+=item.num
      })
    },
  },
  created() {    
    let u1 = localStorage.getItem("p");
    let d = localStorage.getItem("d");
    this.d = d;
    if (Utils.decrypt(d) == 1) {
      this.indexModal = 1;
    }
    if (u1 == null) {
      this.$message("登陆信息失效，请重新登录");
      this.$router.push("/");
    }
    //权限
    queryAllPermission().then((res) => {
      if (res.code == 200) {
        let perArr = res.data.map((item) => {
          item.permission = item.permission.split(":")[1];
          return item;
        });
        localStorage.setItem("perArr", JSON.stringify(perArr));
      } else {
        localStorage.setItem("perArr", JSON.stringify([]));
      }
    });
    dosth().then((res) => {
      this.wsDataChange(res.data);
    });
    //菜单
    loginedAumenu().then((res) => {
      this.menuData = res.data || [];
      res.data.forEach((item) => {
        if (item.children) {
          item.children.forEach((itemChild1) => {
            if (itemChild1.url == this.$route.path) {
              this.bgc = item.sort;
              this.headList.push({
                url: this.$route.path,
                name: this.$route.meta,
                sort: item.sort,
              });
            }
          });
        }
      });
    });
  },
  destroyed(){
      this.websocket.close()
  }
};
</script>

<style lang="less">
.index {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  .light {
    filter: brightness(1.3) !important;
  }
  .aside {
    height: 100%;
    background-color: #141414;
    display: flex;
    flex-direction: column;
    position: relative;
    .top {
      padding-left: 36px;
      width: 220px;
      .img {
        margin: 56px 0 8px 0;
        width: 132px;
        height: 48px;
        margin-bottom: 66px;
      }
    }
    .bottom_img {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 150px;
      height: 170px;
    }
    .el-menu {
      border: none;
      background-color: transparent;
      .el-icon-s-home {
        margin-left: 4px;
      }
      // .el-menu-item.is-active{
      //   color: #D1484F;
      //   background-color: #fff;
      // }
      .img2 {
        display: inline-block;
        width: 32px;
        height: 24px;
        filter: brightness(0.7);
      }
      .title {
        font-size: 14px;
      }
      li.el-menu-item {
        height: 45px;
        line-height: 45px;
        padding-left: 23px !important;
        font-weight: 700;
        // font-family:"PingFang SC";
      }
      .el-submenu__title:hover {
        background-color: #fff !important;
      }
      .el-menu-item-group__title {
        padding: 0px;
      }
      .el-icon-arrow-down:before {
        content: "";
      }
      .el-submenu {
        .el-submenu__title {
          height: 32px;
          line-height: 32px;
          padding: 0px 10px 0px 0px !important;
          font-size: 14px;
          margin: 12px 22px 12px 24px;
          span {
            font-weight: 700;
          }
          .btn {
            border-radius: 16px;
            width: 124px;
            background-color: #d62829 !important;
            color: white !important;
          }
        }
        .el-menu-item {
          height: 30px;
          line-height: 30px;
          padding-left: 64px !important;
        }
      }
    }
  }
  .dbModal{
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #fff;
    border-radius: 8px;
      box-shadow: 1px 1px 8px #888888;
      z-index: 100000000;
       .title {      
        line-height: 40px;
        font-size: 12px;
        display: flex;
        justify-content: space-between;
        padding: 0 20px;
        border-bottom: 1px solid #dddddd;
        i {
          font-size: 14px;
          cursor: pointer;
          &:hover{
            color: #0090ff;
          }
        }
      }
      .dbModal_item{
        padding: 20px 30px;
        max-height: 800px;
      }
  }
  .slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  // transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  transition: all .5s cubic-bezier(1.0, 1.0, 1.0, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for below version 2.1.8 */ {
  transform: translateX(20px);
  // opacity: 0;
}
  .dbModal1{
    position: fixed;
    bottom: 40%;
    right: -1px;
    background-color: #fff;
    border-radius: 6px 0 0 6px;
    cursor: pointer;
    width: 30px;
    line-height: 20px;
    font-size: 14px;
    border: 1px solid #999;
    // color: #0090ff;
     color: #666;
    text-align: center;
    padding: 10px 0;
    &:hover{
      border: 1px solid #0090ff;
      color: #0090ff;
    }
  }
  .main {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    header {
      width: 100%;
      height: 40px;
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      cursor: pointer;
      .left {
        display: flex;
        flex: 1;
        overflow: hidden;
        .arrow {
          width: 42px;
          line-height: 40px;
          color: #b2b2b2;
          border-left: 1px solid #e7e8ea;
          border-right: 1px solid #e7e8ea;
          padding: 0 20px;
          cursor: pointer;
          span {
            display: inline-block;
            width: 8px;
            height: 8px;
            border-top: solid 1px #b2b2b2;
            border-right: solid 1px #b2b2b2;
            transform: rotate(-135deg);
          }
        }
        .head {
          line-height: 40px;
          border-right: solid 1px #e7e8ea;
          width: 120px;
          box-sizing: border-box;
          text-align: center;
          color: rgb(114, 112, 112);
          position: relative;
          .slide-fade-enter-active {
            transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
          }
          .slide-fade-leave-active {
            transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
          }
          .slide-fade-enter,
          .slide-fade-leave-to,
          .slide-fade-leave-active {
            // transform: translateX(120px);

            opacity: 0;
            // width: 0px;
          }
          .close {
            margin-left: 6px;
            .close_img {
              position: relative;
              top: 2px;
              &:hover {
                // color: #e25454;
                filter: brightness(0.7);
              }
            }
          }

          .head_border {
            position: absolute;
            display: inline-block;
            top: 0px;
            width: 100%;
            height: 2px;
            background-color: #2f3c50;
            left: 0px;
          }

          button {
            width: 11px;
            height: 12px;
            border-radius: 50%;
            margin: 0 0 0 12px;
            color: white;
            background-color: #bfbfbf;
            font-size: 10px;
            line-height: 10px;
            cursor: pointer;
          }
        }
        .head_active {
          background-color: #ebf0f5;
          color: #2f3c50;
        }
      }
      .right {
        display: flex;
        line-height: 40px;

        // p:first-child{
        //   width: 60px;
        // }
        .message {
          position: relative;
          .orange {
            position: absolute;
            display: inline-block;
            height: 6px;
            width: 6px;
            border-radius: 50%;
            background-color: #fea900;
            top: 9px;
            left: 29px;
          }
        }

        select {
          border-style: none;
          border-right: 1px solid #d3d6da;
        }
        // i {
        //   font-size: 20px;
        //   margin-left: 16px;
        //   // position: relative;
        //   // top: 16px;
        //   // height: 20px;
        // }
        .el-button {
          padding: 0;
          border: none;
          color: #000;
          margin-left: 10px;
        }
        .unmean {
          margin: 0 20px 0 10px;
          cursor: pointer;
        }
      }
    }
    .content {
      flex: 1;
      display: flex;
      background-color: #ebf0f5;
      .el-input__inner:hover {
        border-color: #15bb9b;
      }
      .el-input__inner:focus {
        border-color: #15bb9b;
      }
      .el-input.is-focus .el-input__inner {
        border-color: #15bb9b;
      }
    }
  }
  .indexModal111 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0);
    z-index: 100;
    display: flex;
    align-items: center;
    .inner {
      width: 450px;
      height: 400px;
      box-sizing: border-box;
      background-color: white;
      margin: auto;
      font-size: 12px;
      display: flex;
      flex-direction: column;
      border-radius: 10px;
      box-shadow: 1px 1px 10px #888888;
      position: relative;
      .title {
        color: #2b2e36;
        line-height: 50px;
        font-weight: bold;
        font-size: 12px;
        display: flex;
        justify-content: space-between;
        padding: 0 30px;
        border-bottom: 1px solid #dddddd;
        i {
          font-size: 18px;
          font-weight: 600;
          cursor: pointer;
        }
      }
      .modal_item3 {
        padding: 0 40px;
        margin: 24px 0;
        .input_item {
          display: flex;
          margin-bottom: 13.5px;
          p {
            width: 80px;
            line-height: 28px;
          }
          .el-input {
            width: 280px;
          }
        }
      }
      .foot {
        width: 100%;
        position: absolute;
        bottom: 24px;
        .finish {
          display: block;
          width: 350px;
          height: 40px;
          border-radius: 6px;
          background-color: #d62829;
          color: #fff;
          cursor: pointer;
          margin: 0 auto;
        }
        .confirm_delete {
          display: block;
          width: 350px;
          height: 40px;
          border-radius: 6px;
          background-color: #ff0101;
          color: #fff;
          cursor: pointer;
          margin: 0 auto;
        }
      }
    }
  }
}
</style>